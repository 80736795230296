import React, { Component } from "react";
import "./Outpatient-Psych.css";
import Container from "../../../components/Container";
import Sidebar from "../../../components/Sidebar";
import ServicesCTA from "../servicesCTA";

class OutpatientPsych extends Component {
  render() {
    return (
        <Container backgroundColor="#ffffff">
            <div className="services outpatient-psych">
                <div>
                    <h1>Outpatient Psychiatric Services</h1>
                    <p> Whenever stress leads to emotional and physical manifestations of illness, psychological as well as social interventions can be very effective in helping individuals resolve these. While medications are a relatively faster method of treating and resolving symptoms, they rarely offer a cure. This is where psychotherapy can help. Psychotherapy, especially insight oriented psychotherapy and psychoanalysis, can provide an understanding of the basis of human behaviors and resulting conflicts and through this understanding, help resolve several of these underlying issues.</p>
                    <p>Depending on individual preference and priorities, we can help patients explore past experiences using psychodynamic and psychoanalytic therapy and resolve their symptoms, or if individual preference is to not delve into their past and stay in the present, we can use cognitive behavioral techniques to help alter and control symptoms.</p>
                    <p>For individuals dealing with substance use disorders, addictions and addictive behaviors, we provide Motivational Enhancement Therapy (MET) to help breakthrough the resistance and facilitate change.</p>
                    <ServicesCTA/>
                </div>
                <Sidebar />
            </div>
        </Container>
    );
  }
}

export default OutpatientPsych;
