import React, { Component } from "react";
import './Home.css';
import Container from "../../components/Container";
import Hero from "../../img/the-inroads-family.png";
import {PHONE_NUMBER} from "../../constants";
import {Link} from "react-router-dom";
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css';


class Home extends Component {

    state = {
        livesImpacted: 0,
        squareFeet: 0,
        specializedTreatment: 0,
    }

    componentDidMount() {
        this.livesImpactedCount = setInterval(() => {
            if (this.state.livesImpacted >= 15000) {
                clearInterval(this.livesImpactedCount);
                this.livesImpactedCount = null;
            } else {
                this.setState((prevState) => ({ livesImpacted: prevState.livesImpacted + 1000 }));
            }
        }, 150);

        this.squareFeetCount = setInterval(() => {
            if (this.state.squareFeet >= 10000) {
                clearInterval(this.squareFeetCount);
                this.squareFeetCount = null;
            } else {
                this.setState((prevState) => ({ squareFeet: prevState.squareFeet + 1000 }));
            }
        }, 200);

        this.specializedTreatmentCount = setInterval(() => {
            if (this.state.specializedTreatment >= 9) {
                clearInterval(this.specializedTreatmentCount);
                this.specializedTreatmentCount = null;
            } else {
                this.setState((prevState) => ({ specializedTreatment: prevState.specializedTreatment + 1 }));
            }
        }, 200);
    }

    componentWillUnmount() {
        clearInterval(this.livesImpactedCount);
        clearInterval(this.squareFeetCount);
        clearInterval(this.specializedTreatmentCount);
    }



  render() {
    return (
        <Container backgroundColor="#ffffff">
            <div className="home">

                <div className="message">
                    Your Pathway to Personal Empowerment and Recovery<br />
                    <span>Call Now ~ {PHONE_NUMBER}</span>
                </div>


                {/*<div className="message">*/}
                {/*  NOW ACCEPTING PATIENTS FOR DAYTIME AND EVENING INTENSIVE OUTPATIENT PROGRAM (IOP)*/}
                {/*</div>*/}

                <div className="heroImg">
                    <img src={Hero} alt="The Inroads Family"/>
                </div>

                <div className="message">
                    Why Recover with us?
                </div>

                <div className="fullWidth">
                    {/*<div className="centerTxt">*/}
                    {/* <p>Addiction and mental health disorders are chronic and treatable diseases.<br /> Our expert team’s patient-first approach can give you or your loved one the medical support, confidence and tools needed for a healthier life in recovery.</p>*/}
                    {/*</div>*/}
                    <div className="threeUp">
                        <div className="kpi">
                            <div className="reason">Lives we have impacted</div>
                            <div className="value"><Odometer value={this.state.livesImpacted} />+</div>
                        </div>
                        <div className="kpi">
                            <div className="reason">Square foot Treatment Facility</div>
                            <div className="value"><Odometer value={this.state.squareFeet} />+</div>
                        </div>
                        <div className="kpi">
                            <div className="reason">Specialized Treatment Options</div>
                            <div className="value"><Odometer value={this.state.specializedTreatment} />+</div>
                        </div>
                    </div>
                </div>

                <div className="threeUp">

                    <div className="homeItem">
                        <h1>Welcome</h1>
                        <div className="itemText">
                        <p>Inroads offers hope to individuals struggling with mental health and substance use
                                disorders. We help transform lives by providing evidence based treatment at all levels
                                of care.</p>
                            <p>We have a team of very dedicated employees who all believe in the mission of Inroads to
                                Recovery and who want to make a difference in the lives of not just the patients that we
                                serve but their families and the community at large. Our holistic approach cultivates a
                                deep and more meaningful patient relationship. Through this approach we INCLUDE, IMPROVE
                                and INSPIRE.</p>
                        </div>
                        <div className="btn"><Link to="/Contact">Contact Us</Link></div>
                    </div>

                    <div className="homeItem">
                        <h1>About Us</h1>
                        <div className="itemText">
                            <p>Inroads to Recovery is a mental health and substance use treatment program that started
                                its operations in 2004 with a vision to assist individuals find their path to
                                change.</p>
                            <p>We offer an individualized and multi dimensional evaluation and treatment plan through
                                which individuals are challenged to find solutions to their problems. We accomplish this
                                using evidence-based medication management along with individual, couples and family
                                therapy sessions.</p>
                        </div>
                        <div className="btn"><Link to="/about">Read More About Us</Link></div>
                    </div>

                    <div className="homeItem">
                        <h1>Services</h1>
                        <div className="itemText">
                            <p>Inroads to Recovery will assist individuals sustain substantial stability and recovery
                                from mental health and addiction to alcohol and other drugs. We offer the following
                                programs:</p>
                            <ul>
                                <li>Psychiatric Evaluations and Medication Management</li>
                                <li>Outpatient Psychotherapy</li>
                                <li>Intensive Outpatient Therapy (IOP)</li>
                                <li>Substance Use Evaluations</li>
                                <li>Residential Treatment</li>
                                <li>Opiate Use Treatment</li>
                                <li>Alcohol Use Disorder</li>
                                <li>Methamphetamine Use Disorders</li>
                            </ul>
                        </div>
                        <div className="btn"><Link to="/services">Read More About Our Services</Link></div>
                    </div>


                </div>

            </div>
        </Container>


    );
  }
}

export default Home;
