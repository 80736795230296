export const PHONE_NUMBER = '402-932-2248';
export const FAX_NUMBER = '402-932-3557';
export const ADDRESS = '2808 N 75th St. Omaha NE, 68134';

export const BUSINESS_HOURS = {
    Monday: '8:30am - 4:45pm',
    Tuesday: '8:30am - 4:45pm',
    Wednesday: '8:30am - 4:45pm',
    Thursday: '8:30am - 4:45pm',
    Friday: '8:30am - 2:45pm',
};

export const IOP_HOURS = {
    Monday: '12pm - 3pm | 5pm - 8pm',
    Tuesday: '12pm - 3pm | 5pm - 8pm',
    Wednesday: 'N/A',
    Thursday: '12pm - 3pm | 5pm - 8pm',
    Friday: '10am - 1pm | 2pm - 5pm',
    Saturday: '10am - 1pm | 2pm - 5pm',
    Sunday: '10am - 1pm | 2pm - 5pm',
};
