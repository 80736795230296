import React, { Component } from "react";
import "./Services.css";
import Container from "../../components/Container";
import Sidebar from "../../components/Sidebar";
import ServicesList from "./ServicesList";

class Services extends Component {
  render() {
    return (
        <Container backgroundColor="#ffffff">
          <div className="services">
              <div>
                  <h1>Services</h1>

                  <h2>Programs available through Inroads</h2>
                  <p>Inroads to Recovery will assist individuals sustain substantial stability and recovery from mental health and addiction to alcohol and other drugs. We help transform lives by providing evidence-based treatment at all levels of care for mental health and substance abuse issues. Our holistic approach cultivates a deep, meaningful patient relationship. Through this approach we <strong>INCLUDE</strong> <strong>IMPROVE</strong> <span className="ital">and</span> <strong>INSPIRE</strong>.</p>
                  <p>We offer the following programs:</p>
                  <ServicesList />
                  <h2>To schedule an appointment or for more information
                      Call <a href="tel:402-932-2248">(402) 932-2248</a> or <a href="mailto:info@inroadstorecovery.com">send us an email</a> .</h2>
              </div>
              <Sidebar />
          </div>
        </Container>
    );
  }
}

export default Services;
