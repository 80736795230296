export const resources = [
    {
        "category": "Anger Management",
        "title": "AIMS for Anger Management",
        "description": "The app provides users with education about anger, opportunities for finding support, the ability to create an anger management plan, anger tracking, and tools to help manage angry reactions.",
        "link": "https://mobile.va.gov/app/aims-anger-management",
        "img": "AIMS-for-Anger-Management"
    },
    {
        "category": "Anxiety",
        "title": "Insight Timer Meditation, Sleep, Music",
        "description": "The number 1 free meditation app. Guided meditations, sleep music tracks and talks led by the top meditation and mindfulness experts, neuroscientists, psychologists and teachers from Stanford, Harvard, the University of Oxford and more.",
        "link": "https://insighttimer.com/",
        "img": "Insight-Timer",
    },
    {
        "category": "Anxiety",
        "title": "Mindfulness Coach",
        "description": "Mindfulness Coach was developed for people who may be experiencing emotional distress and for those wanting to maintain healthy coping practices. The app can be used on its own by those who would like mindfulness tools, or to enhance face-to-face care with a healthcare professional.",
        "link": "https://mobile.va.gov/app/mindfulness-coach",
        "img": "Mindfulness-Coach",
    },
    {
        "category": "Bipolar / Depression",
        "title": "Daylio",
        "description": "Self-Care Bullet Journal with Goals Mood Diary & Happiness Tracker. Keep a diary and capture your day without writing down a single word!",
        "link": "https://daylio.net/",
        "img": "Daylio",
    },
    {
        "category": "Couples",
        "title": "Couples Coach",
        "description": "Couples Coach pairs up partners as they explore assessments and share results, learn about different approaches to common relationship issues and review available resources in their communities. It also includes comprehensive relationship information for couples living with PTSD.",
        "link": "https://mobile.va.gov/app/couples-coach",
        "img": "Couples-Coach"
    },
    {
        "category": "Meditation",
        "title": "Headspace: Meditation & Sleep (Subscription Required)",
        "description": "Headspace is your guide to mindfulness for your everyday life. Learn meditation and mindfulness skills from world-class experts like Headspace co-founder Andy Puddicombe, and develop tools to help you focus, breathe, stay calm, and create balance in your life — whether you need stress relief or help to get restful sleep.",
        "link": "https://www.headspace.com/",
        "img": "Headspace"
    },
    {
        "category": "PTSD",
        "title": "PTSD Coach",
        "description": "The PTSD Coach app can help you learn about and manage symptoms that often occur after trauma.",
        "link": "https://www.ptsd.va.gov/appvid/mobile/ptsdcoach_app.asp",
        "img": "PTSD-Coach"
    },
    {
        "category": "Tobacco",
        "title": "Stay Quit Coach",
        "description": "Stay Quit Coach is designed to help with quitting smoking. It is intended to serve as a source of readily available support and information for adults who are already in treatment to quit smoking, to help them stay quit even after treatment ends.",
        "link": "https://mobile.va.gov/app/stay-quit-coach",
        "img": "Stay-Quit-Coach"
    },
    {
        "category": "PTSD",
        "title": "PTSD Family Coach",
        "description": "Living with a family member who has PTSD can be stressful. PTSD Family Coach is an app similar to PTSD Coach. PTSD Family Coach provides support for concerned family members of those with PTSD.",
        "link": "https://www.ptsd.va.gov/appvid/mobile/familycoach_app.asp",
        "img": "PTSD-Family-Coach"
    },
    {
        "category": "Sleep / Insomnia",
        "title": "Insomnia Coach",
        "description": "Insomnia Coach is designed for Veterans, Military Service members, and others who are suffering from insomnia. The app is based on Cognitive Behavioral Therapy for Insomnia (CBT-i).",
        "link": "https://mobile.va.gov/app/insomnia-coach",
        "img": "Insomnia-Coach"
    },
    {
        "category": "Sleep / Insomnia",
        "title": "CBT-i Coach",
        "description": "CBT-i Coach is for people who are engaged in Cognitive Behavioral Therapy for Insomnia with a health provider, or who have experienced symptoms of insomnia and would like to improve their sleep habits.",
        "link": "https://mobile.va.gov/app/cbt-i-coach",
        "img": "CBT-i-Coach"
    },
    {
        "category": "Substance Use",
        "title": "I Am Sober",
        "description": "I Am Sober more than just a free sobriety counter app. Along with tracking your sober days, it helps you build new habits and provides ongoing motivation by connecting you to a wide network of people all striving for the same goal: staying sober one day at a time.",
        "link": "https://iamsober.com/en/site/home",
        "img": "i-am-sober"
    },
    {
        "category": "Substance Use",
        "title": "Recovery Path",
        "description": "Recovery Path allows you to navigate your unique path to sustained recovery, share progress with others, and receive support.",
        "link": "https://www.recoverypath.com/",
        "img": "Recovery-Path"
    },
    {
        "category": "Anxiety / Depression",
        "title": "My Possible Self",
        "description": "Managing anxiety, tackling depression, easing stress and improving sleep can help you enjoy doing more of the things you love.",
        "link": "https://www.mypossibleself.com/",
        "img": "My-Possible-Self"
    },
    {
        "category": "Podcasts",
        "title": "Radio Headspace",
        "description": "Join every weekday morning to take a few moments to step out of the internal chatter and external noise. We'll pause and reflect to consider what brings us together in this shared human condition and how we can live a life that best reflects our limitless potential.",
        "link": "https://www.headspace.com/podcast",
        "img": "Headspace"
    },
    {
        "category": "Podcasts",
        "title": "The Happiness Lab with Dr. Laurie Santos",
        "description": "Yale professor Dr. Laurie Santos has studied the science of happiness and found that many of us do the exact opposite of what will truly make our lives better. Laurie will take you through the latest scientific research and share some surprising and inspiring stories that will change the way you think about happiness.",
        "link": "https://www.drlauriesantos.com/happiness-lab-podcast",
        "img": "The-Happiness-Lab"
    },
    {
        "category": "Podcasts",
        "title": "Mental Illness Happy Hour",
        "description": "The Mental Illness Happy Hour is a weekly online podcast that interviews comedians, artists, friends, and the occasional doctor. Each episode explores mental illness, trauma, addiction and negative thinking.",
        "link": "https://mentalpod.com/",
        "img": "Mental-Illness-Happy-Hour"
    },
    {
        "category": "Podcasts",
        "title": "Ten Percent Happier with Dan Harris",
        "description": "Mental traits such as happiness, calm, generosity, compassion, and connection are not hardwired, unalterable factory settings; they are, in fact, skills that can be trained. On this show, you'll learn how.",
        "link": "https://www.tenpercent.com/podcast",
        "img": "Ten-Percent-Happier-with-Dan-Harris",
    },
    {
        "category": "Podcasts",
        "title": "The Addicted Mind Podcast",
        "description": "The Addicted Mind Podcast is about understanding addiction from a research and treatment perspective. We will dive into what drives the addictive process, explores the latest research on addiction, and talk about the latest addiction treatment options.",
        "link": "https://theaddictedmind.com/",
        "img": "The-Addicted-Mind-Podcast"
    }
]
