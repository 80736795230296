import React, { Component } from "react";
import {Link} from "react-router-dom";
import servicesData from '../servicesData.js';
import './ServicesList.css'

class ServicesList extends Component {
  render() {
    return (
        <div className="services-list">
          <ul>

              {/* To update the submenu, routes and the sidebar, edit the array in inroads\src\pages\Services\servicesData.js */}

              {servicesData.map(service => (
                  <li key={service.name}>
                      <Link to={service.link}>{service.name}</Link>
                  </li>
              ))}

          </ul>
        </div>
    );
  }
}

export default ServicesList;
