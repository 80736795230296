import React, { Component } from "react";
import "./Resources.css";
import Container from "../../components/Container";
import Sidebar from "../../components/Sidebar";
import { resources } from "./resourceData.js";


// Associate Component
function Resource({ resource }) {
    return (
        <div className="resource">
            <div className="resourceImg">
                <img src={`/resource-img/${resource.img}.jpg`} alt={resource.title}/>
            </div>
            <div className="desc">
                <h2 className="title"><a href={resource.link} target="_blank" rel="noopener noreferrer">{resource.title}</a>
                </h2>
                <h3 className="category">{resource.category}</h3>
                <p>{resource.description}</p>
            </div>

        </div>
    );
}


class Resources extends Component {
    render() {
        return (
            <Container backgroundColor="#ffffff">
                <div className="page-sidebar resources">
                    <div>
                        <h1>Resources</h1>

                        {resources.map((resource, index) =>
                            <Resource key={index} resource={resource}/>
                        )}
                    </div>
                    <Sidebar />
                </div>

            </Container>
        );
    }
}

export default Resources;
