import React, { Component } from "react";
import "./IOP.css";
import Container from "../../../components/Container";
import Sidebar from "../../../components/Sidebar";
import {PHONE_NUMBER, ADDRESS, IOP_HOURS} from "../../../constants";
import ServicesCTA from "../servicesCTA";

class IOP extends Component {

    renderIOPHours(hours) {
        if (hours === 'N/A') {
            return <span className="na">{hours}</span>;
        } else {
            return <span>{hours}</span>;
        }
    }
    render() {

        const phoneNumberLink =PHONE_NUMBER.replace(/-/g, '');

        return (
            <Container backgroundColor="#ffffff">
                <div className="iop">
                    <div className="iop-txt">
                        <div>
                            <h1>Intensive Outpatient Treatment (IOP)</h1>
                            <p>Inroads to Recovery’s Intensive Outpatient Treatment Program (IOP) is identified as a separate outpatient program that provides culturally and linguistically appropriate services.</p>
                            <p>The Intensive Outpatient Program (IOP) provides high quality, evidence based treatment for adults experiencing problematic substance use, bipolar disorder (including mania; and for types I and II), unipolar depression, self-harm, trauma, and co-occurring mental health conditions and chemical dependency that does not rely on detoxification. The program is appropriate for all individuals who are medically stable and have completed any necessary detoxification, inpatient psychiatric care, or residential treatment programs.</p>
                            <p>The Intensive Outpatient Program (IOP) program consists of a scheduled series of sessions appropriate to the person-centered plans of the persons served and address issues described under OP level of care, but at a higher level of intensity and time spent in treatment.</p>
                            <p>At Inroads to Recovery, our IOP Program is designed to make treatment more effective by resolving trauma, helping individuals learn to lead a full and balanced recovery-supportive lifestyle, focusing on home safety.</p>
                            <p>These may include services provided during evenings and/or interventions delivered by a variety of service providers.&nbsp; <em>Modalities</em> include individual, group, and education on wellness, recovery, and resiliency.&nbsp; IOP may function as a step-down program from inpatient, partial hospitalization, detoxification / withdrawal support, or residential services.&nbsp; It is also used to prevent or minimize the need for inpatient or residential level of treatment.&nbsp; These services help the patients to safely stay sober and maintain positive mental health through at least nine (9) hours or more of services per week for 6 weeks.</p>
                            <p>We provide an Intensive Outpatient Treatment Program (IOP), with value to the community for patients exhibiting substance use, including co-occurring (psychiatric and substance use) disorders as applicable, when there is a supportive and stable living environment but a higher level of care is needed.&nbsp;</p>
                            <p>Intensive Outpatient Programming at Inroads to Recovery includes individual, family and other treatment options that are tailored to patients.</p>

                            <h2><strong>Individual Therapy</strong></h2>
                            <p>Individual therapy focusses on developing the skills necessary to maintain sobriety and prevent a relapse. Psychoeducation helps individuals better understand the complex correlation between their mental health and substance misuse.</p>

                            <h2><strong>Family Therapy</strong></h2>
                            <p>Family therapy facilitates honest communication between family members and the patient and provides education about the nature of the patient’s mental health conditions and substance use disorder.</p>

                            <h2><strong>Group Therapy</strong></h2>
                            <p>In the group therapy sessions, patients get the opportunity to gather information from peers through observation and feedback and use the information to practice new interpersonal skills or make desired changes in their own lives.</p>

                            <h2><strong>Basic Structure of Outpatient Programming:</strong></h2>
                            <p>Intensive Outpatient Programming (IOP) is the second level of outpatient programming and consists of a six-week program. The program requires a weekly commitment to attend three group therapy sessions. There is a strong focus on relapse prevention. IOP patients are also introduced to local support groups.</p>
                            <h2><strong>Objectives</strong></h2>
                            <ul>
                                <li>Patients will have access to Intensive Outpatient behavioral health services</li>
                                <li>Patients will acquire in a timely manner psychiatric, mental health, and/or substance use assessments for IOP programming</li>
                                <li>Patients will acquire knowledge and prevention skills from Mental Health and/or Substance Use treatment offered through intensive outpatient level of care</li>
                                <li>Measurement of patient progress in treatment will occur at the beginning of treatment, throughout treatment, and at the end of treatment</li>
                                <li>Patients will improve and/or maintain desired health status/behavior for at least three months</li>
                                <li>Patients will remain abstinent from all Mood Altering Chemicals (MAC) for at least three months</li>
                            </ul>

                            <h2>Program Parameters</h2>
                            <p>Population Served:</p>
                                    <ol>
                                        <li>Adults</li>
                                        <li>Patients with mental health problems</li>
                                        <li>Patients with substance use disorders.</li>
                                        <li>Patients with co-occurring disorders</li>
                                        <li>Patients that are willing to come to treatment at Inroads to Recovery either as a
                                            voluntary patients or under a court order, recognizing that this is an unlocked facility
                                            offering treatment for voluntary patients who want to get better and get help
                                        </li>
                                    </ol>

                            <h2>Frequency of Services:</h2>
                            <p>At least nine (9) hours or more of services based on demand and staff availability</p>

                            <h2>Location:</h2>
                            <p>Intensive Outpatient (IOP) level of care at Inroads to Recovery</p>
                            <p>{ADDRESS}</p>

                            <div className="iop-hours-wrapper">
                                <h2>Intensive Outpatient Program (IOP) Hours</h2>
                                <div className="iop-hours">
                                    <div><span>Monday:</span> {this.renderIOPHours(IOP_HOURS.Monday)}</div>
                                    <div><span>Tuesday:</span> {this.renderIOPHours(IOP_HOURS.Tuesday)}</div>
                                    <div><span>Wednesday:</span> {this.renderIOPHours(IOP_HOURS.Wednesday)}</div>
                                    <div><span>Thursday:</span> {this.renderIOPHours(IOP_HOURS.Thursday)}</div>
                                    <div><span>Friday:</span> {this.renderIOPHours(IOP_HOURS.Friday)}</div>
                                </div>
                            </div>

                            <p><a href={"tel:" + phoneNumberLink}><strong>Contact our office at {PHONE_NUMBER}</strong></a></p>
                            <ServicesCTA/>
                        </div>
                    </div>
                    <Sidebar />
                </div>
            </Container>
        );
    }
}

export default IOP;
