import React, { Component } from "react";
import './servicesCTA.css';

class ServicesCTA extends Component {
  render() {
    return (

        <div className="servicesCTA">
          <h2>Transform Your Tomorrow, Today.</h2>
          <p>
            Call <a href="tel:402-932-2248">402-932-2248</a> to Start Your Journey with Inroads to Recovery.
          </p>
          <h3>Together, We Can Create a New Path Forward.</h3>
        </div>

    );
  }
}

export default ServicesCTA;
