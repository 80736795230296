import React, { useState, createRef } from "react";
import "./Contact.css";
import Container from "../../components/Container";
import Sidebar from "../../components/Sidebar";
import ReCAPTCHA from "react-google-recaptcha";

function Contact() {

  const recaptchaRef = createRef();

  const [messageStatus, setMessageStatus] = useState({});

  // isVerified state for disabling/enabling the button
  const [isVerified, setIsVerified] = useState(false);

  // Add recaptcha verification logic
  const handleRecaptcha = value => {
    if (value) {
      // If reCAPTCHA has been filled out, set isVerified to true
      setIsVerified(true);
    }
  };

  // 1. Add state to manage form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    department: 'Appointments',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const recaptchaValue = recaptchaRef.current.getValue();
    if(!recaptchaValue) {
      console.error('ReCaptcha validation failed');
      return;
    }

    try {
      fetch('/handleForm.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({...formData, 'g-recaptcha-response': recaptchaValue}).toString()
      })
          .then(response => {
            if (response.ok) {
              return response.text();
            } else {
              throw new Error('Server response was not ok.');
            }
          })
          .then(text => {
            console.log(text);
            setMessageStatus({ success: true, msg: 'Message successfully sent!' });

            // Reset form data to initial state
            setFormData({
              name: '',
              email: '',
              phone: '',
              department: 'Appointments',
              message: ''
            });
            // Update state or UI to reflect successful submission

            // Reset recaptcha
            if (recaptchaRef.current) {
              recaptchaRef.current.reset();
              setIsVerified(false);
            }

          })
          .catch(error => {
            console.error('Error:', error);
            setMessageStatus({ success: false, msg: 'Failed to send the message. Please try again!' });
            // Update state or UI to reflect error
          });
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
      <Container backgroundColor="#ffffff">
        <div className="contact">
          <div className="contactForm">
            <h1>Contact Us</h1>
            {/* 2. Add the contact form JSX code */}
            <form onSubmit={handleSubmit}>
              <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Your Name"
              />
              <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Your Email"
              />
              <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Your Phone"
              />
              <p><strong>Please Select One:</strong></p>
              <select
                  name="department"
                  value={formData.department}
                  onChange={handleChange}
              >
                <option value="Appointments">Appointments</option>
                <option value="Medical Records">Medical Records</option>
                <option value="Billing">Billing</option>
                <option value="Medications">Medications</option>
                <option value="Residential Treatment">Residential Treatment</option>
                <option value="Other">Other</option>
              </select>
              <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Your Message"
              />
              <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LeZREkpAAAAALul8y4Yz-VMoYiobA-GPD7tm5AW"
                  onChange={handleRecaptcha}
                  onExpired={() => setIsVerified(false)}  // Add this line
              />
              <button type="submit" disabled={!isVerified || !formData.name || !formData.email || !formData.phone || !formData.message}>Send Message</button>
            </form>
            <div className={`messageStatus ${messageStatus.success ? 'success' : 'error'}`}>
              {messageStatus.msg}
            </div>
          </div>
          <Sidebar/>
        </div>
      </Container>
  );
}

export default Contact;
