import React, {useEffect} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import './App.css';
import "./global.css";

import Header from './components/Header';
import Nav from './components/Nav';
import Footer from "./components/Footer";

import Home from "./pages/Home";
import About from "./pages/About";
import Faq from "./pages/Faq";
import Services from "./pages/Services";
import OurTeam from "./pages/Our-Team";
import Contact from "./pages/Contact";
import Testimonials from "./pages/Testimonials";
import PirzadaSattar from "./pages/Our-Team/PirzadaSattar";


import servicesData from './pages/Services/servicesData.js';
import Community from "./pages/Community";
import Resources from "./pages/Resources";

// const OpenPdfInNewTab = ({ url }) => {
//     useEffect(() => {
//         window.open(url, "_blank"); // "_blank" opens a new tab
//     }, [url]);
//
//     return null;
// };

const RedirectToPdf = ({ url }) => {
    useEffect(() => {
        window.location.href = url;
    }, [url]);

    return null;
};

const newpatient = process.env.PUBLIC_URL + '/forms/New Patient Intake Form.pdf';
const yearly = process.env.PUBLIC_URL + '/forms/Yearly PT Consent Form.pdf';


function App() {
  return (
      <Router basename="/">
          <Header />
          <Nav />
              <Routes >
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/Contact" element={<Contact />} />
                  <Route path="/Our-Team" element={<OurTeam />} />
                  <Route path="/Resources" element={<Resources />} />
                  <Route path="/resources" element={<Resources />} />
                  <Route path="/Testimonials" element={<Testimonials />} />
                  <Route path="/Community-Partnerships" element={<Community />} />
                  <Route path="/Our-Team/Dr-Pirzada-Sattar-MD" element={<PirzadaSattar />} />
                  <Route path="/Frequently-Asked-Questions" element={<Faq />} />

                  <Route path="/newpatient" element={<RedirectToPdf url={newpatient} />} />
                  <Route path="/yearly" element={<RedirectToPdf url={yearly} />} />

                  {/* To update the submenu, routes and the sidebar, edit the array in inroads\src\pages\Services\servicesData.js */}

                  {servicesData.map(service => (
                      <Route
                          key={service.name}
                          path={service.link}
                          element={React.createElement(service.component)}
                      />
                  ))}

              </Routes>
          <Footer />
      </Router>
  );
}

export default App;
