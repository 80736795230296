import React, {Component} from "react";
import {Link} from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import "./Nav.css";
import logo from "../../img/inroads-logo-314.png";
import Container from "../Container";
import servicesData from "../../pages/Services/servicesData.js";

class Nav extends Component {
    constructor() {
        super();
        this.state = {
            isMenuOpen: false,
        };
        this.closeMenu = this.closeMenu.bind(this); // this line is added
    }

    componentDidMount() {
        document.addEventListener('click', this.handleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDocumentClick);
    }

    handleDocumentClick = (event) => {
        const isMenuClick = event.target.closest('.menu-desktop') || event.target.closest('.menu-mobile') || event.target.closest('.hamburger');
        console.log(`Clicked on element: ${event.target.tagName} with classes: ${event.target.className}`);
        if (!isMenuClick) {
            this.setState({ isMenuOpen: false });
        }
    };

    toggleMenu = () => {
        this.setState({ isMenuOpen: !this.state.isMenuOpen });
    };

    toggleSubmenu = (index) => {
        console.log("Clicked to toggle submenu with index:", index); // Log statement

        this.setState(prevState => {
            let newIndex = prevState.activeSubmenu === index ? null : index;
            console.log("activeSubmenu set to:", newIndex);
            return { activeSubmenu: newIndex };
        });
    };

    closeMenu = () => {
        this.setState({ isMenuOpen: false });
    };

    render() {

        const menuItems = [
            {name: 'Home', link: '/', isExternal: false},
            {name: 'About Us', link: '/about', isExternal: false, submenu: [
                    {name: 'Our Team', link: '/Our-Team', isExternal: false},
                    {name: 'FAQ', link: '/Frequently-Asked-Questions', isExternal: false}
                ]},
            {name: 'Services', link: '/services', isExternal: false, submenu: servicesData},
            {name: 'Residential Program', link: '/services/Residential-Program', isExternal: false},
            {name: 'Contact', link: './Contact', isExternal: false},
            {name: 'Testimonials', link: '/Testimonials', isExternal: false},
            {name: 'Community Partnerships', link: '/Community-Partnerships', isExternal: false},
            {name: 'Patient Portal', link: 'https://patientonlineportal.com/idp/account/signin?ReturnUrl=%2fidp%2fissue%2fwsfed%3fwa%3dwsignin1.0%26wtrealm%3dhttps%253a%252f%252fpatientonlineportal.com%252f%26wctx%3drm%253d0%2526id%253dpassive%2526ru%253d%25252f%26wct%3d2021-02-22T19%253a52%253a17Z%26wreply%3dhttps%253a%252f%252fpatientonlineportal.com%252f&wa=wsignin1.0&wtrealm=https%3a%2f%2fpatientonlineportal.com%2f&wctx=rm%3d0%26id%3dpassive%26ru%3d%252f&wct=2021-02-22T19%3a52%3a17Z&wreply=https%3a%2f%2fpatientonlineportal.com%2f', isExternal: true}
        ];
        const { activeSubmenu } = this.state;


        /* This is used to populate the desktop and mobile menu link lists */

        const desktopMenu = (
            <>
                {menuItems.map((item) => {
                    return (
                        <div className="menu-item" onClick={this.closeMenu} key={item.name}>
                            {item.isExternal
                                ? <a href={item.link} target="_blank" rel="noreferrer">{item.name}</a>
                                : <Link to={item.link}>{item.name}</Link>
                            }
                            {item.submenu &&
                                <div className="submenu">
                                    {item.submenu.map(service => (
                                        <div className="sub-item" key={service.name}>
                                            <Link to={service.link}>{service.name}</Link>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    );
                })}
            </>
        );

        const mobileMenu = (
            <div className="mobile">
                <Menu
                    isOpen={this.state.isMenuOpen}
                    onClose={this.closeMenu}
                    customBurgerIcon={false}
                    right
                >
                    {menuItems.map((item, index) => (
                        <div key={index} className="bm-item" style={{ display: 'block' }} onClick={(e) => {
                            if (item.submenu) {
                                e.stopPropagation(); // stop navigation when the menu item has submenu
                                this.toggleSubmenu(index); // toggle the submenu
                            } else {
                                this.closeMenu(); // close the menu
                            }
                        }}>
                            {item.isExternal ? (
                                <a href={item.link} target="_blank" rel="noreferrer">{item.name}</a>
                            ) : (
                                <Link to={item.link}>{item.name}</Link>
                            )}
                            {item.submenu && activeSubmenu === index && (
                                <div className={`submenu ${activeSubmenu === index ? "active" : ""}`}>
                                    {item.submenu.map((subItem, subIndex) =>
                                        <div key={subItem.name} className="sub-item">
                                            <Link to={subItem.link} onClick={this.closeMenu}>{subItem.name}</Link>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </Menu>
            </div>
        );

        return (
            <Container backgroundColor="#60bdc5">
                <div className="nav">
                    <div className="logo"><Link to="/"><img src={logo} alt="Inroads to Recovery"/></Link></div>
                    <div className="hamburger" onClick={() => this.toggleMenu()}>
                        <i className="fa-solid fa-bars"></i>
                    </div>
                    <div className="menu-desktop">
                        {desktopMenu}
                    </div>
                </div>
                {mobileMenu}

            </Container>
        );

    }
}

export default Nav;
