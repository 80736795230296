import React, { Component } from "react";
import Container from "../../../components/Container";
import ServicesCTA from "../servicesCTA";
import Sidebar from "../../../components/Sidebar";

class Kratom extends Component {
  render() {
    return (
        <Container backgroundColor="#ffffff">
          <div className="services kratom">
            <div>
              <h1>Kratom Addiction</h1>
              <h2>What is Kratom?</h2>
              <p>Kratom is an herbal extract derived from the leaves of a tropical evergreen tree (Mitragyna speciosa)
                native to Southeast Asia. It has a long history of use in traditional medicines due to the range of
                sedative and stimulant-like effects associated with its use. The biologically active chemicals in kratom
                interact with opioid receptors in humans to produce sedation, pleasure, and decreased pain. Kratom also
                interacts with adrenergic receptors to produce stimulant effects. Effects occur within 5-10 minutes
                after ingestion and can last for 2-5 hours.</p>

              <p>At low doses kratom can act like a stimulant, giving users increased energy, sociability, and
                alertness. At higher doses kratom reduces pain and provides feelings of euphoria, while at very high
                doses it can act as a sedative and make users drowsy.</p>

              <p>Although traditionally used to combat fatigue and improve work productivity among farm populations in
                Southeast Asia, during the last two decades kratom has gained popularity as a recreational drug in the
                United States and Europe. Kratom is not currently an illegal substance in the United States and is
                widely available for purchase at gas stations, Vape supply stores and on the internet where it is
                marketed as a dietary or herbal supplement.</p>


              <h2>
                <strong>Side Effects of Kratom Use</strong>
              </h2>
              <p>There are a range of side effects associated with the use of Kratom. The most common minor side effects
                include nausea, vomiting, and constipation. Reported health effects of Kratom are dose-dependent, and
                can also include:</p>
              <ul>
                <li>Loss of appetite</li>
                <li>Weight loss</li>
                <li>Dry mouth</li>
                <li>Itching</li>
                <li>Sweating</li>
                <li>Increased urination</li>
                <li>Rapid heart rate</li>
                <li>High blood pressure</li>
                <li>Skin darkening</li>
                <li>Liver damage</li>
                <li>Muscle pain</li>
                <li>Dizziness</li>
                <li>Drowsiness</li>
                <li>Sedation</li>
                <li>Decreased breathing</li>
                <li>Hallucinations, delusion, and confusion</li>
                <li>Seizure</li>
                <li>Coma</li>
              </ul>

              <h2>
                <strong>Dangers of Kratom Use</strong>
              </h2>

              <p>Kratom use often coincides with polydrug use, especially among those with opioid use disorder, leading
                to potential herb-drug interactions and adverse outcomes affecting the central nervous system. Reports
                have linked kratom use to liver damage, fatalities, and severe outcomes such as seizures and high blood
                pressure. Data from poison control centers and the FDA reveal a concerning trend of adverse events,
                including deaths, associated with kratom use.</p>

              <h2>
                <strong>Signs of Kratom Withdrawal</strong>
              </h2>
              <p>
                People who consume kratom may report withdrawal symptoms similar to those that occur after opioid use.
                Physical withdrawal symptoms of Kratom commonly experienced include:
              </p>
              <ul>
                <li>Muscle spasms and pain</li>
                <li>Sleeping difficulty</li>
                <li>Watery eyes/nose</li>
                <li>Hot flashes</li>
                <li>Fever</li>
                <li>Decreased appetite</li>
                <li>Diarrhea</li>
              </ul>
              <p>
                Psychological withdrawal symptoms commonly associated with kratom use include:
              </p>
              <ul>
                <li>Restlessness</li>
                <li>Tension</li>
                <li>Anger</li>
                <li>Sadness</li>
                <li>Nervousness</li>
              </ul>
              <h2>
                <strong>Treatment for Kratom Addiction</strong>
              </h2>
              <p>Dangers of Kratom Use and the Path to Recovery</p>
              <p>At our treatment facility, we understand the complex challenges associated with kratom addiction. Kratom use often intertwines with other substances, posing significant risks to your health, including potential herb-drug interactions and severe outcomes such as liver damage and even death.</p>
              <p>We've seen the impact firsthand, and we're here to help. If you or a loved one is struggling with kratom addiction, seeking professional help is crucial. For some, kratom withdrawal symptoms can be mild and short-lived, but for others, they can be more severe. Our medically supervised program ensures your safety and comfort as you navigate withdrawal symptoms and take your first steps toward recovery.</p>
              <p>In our treatment program, we offer various levels of care tailored to your individual needs, from residential level of care to outpatient care. Our approach is personalized, addressing not only the physical aspects of addiction but also the social, psychological, and behavioral factors underlying your kratom abuse.</p>
              <p>While there are no specific medical treatments for kratom addiction, our team can explore various supportive options, including medication-assisted treatment and behavioral therapy, to aid in your recovery journey. We're here to work with you, understanding your unique needs and helping you achieve long-term freedom from kratom addiction.</p>
              <p>If you're ready to take the first step toward recovery, reach out to our team of addiction specialists. Together, we can find the best path forward for you or your loved one, offering hope and support throughout the journey to sobriety.</p>
              <ServicesCTA/>
            </div>
            <Sidebar/>
          </div>
        </Container>
    );
  }
}

export default Kratom;
