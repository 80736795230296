import React, { Component } from "react";
import './About.css';
import Container from "../../components/Container";
import Sidebar from "../../components/Sidebar";

class About extends Component {
  render() {
    return (
        <Container backgroundColor="#ffffff">
    <div className="page-sidebar about-us">
      <div>
        <h1>About Us</h1>
        <div className="about-us-text">
          <h2>Inroads to Recovery</h2>
          <p>Inroads to Recovery is a mental health and substance use treatment program that started its operations in 2004 with a vision to assist individuals find their path to change.</p>
          <p>Over the years, we expanded to provide psychiatric evaluations, medication management, outpatient mental health and substance use disorders therapy, intensive outpatient program and residential treatment program.</p>
          <p>We offer an individualized and multi-dimensional evaluation and treatment plan through which individuals are challenged to find solutions to their problems. We accomplish this using evidence-based medication assisted treatments along with individual, couples and family therapy sessions.</p>
          <p>We are grateful to the Greater Omaha-Council Bluffs community that has allowed us to serve them for so many years, and allowing us to grow over the past decade and a half. </p>
        </div>
        <div className="about-us-text">
          <h2>Our Mission</h2>
          <p>It is the mission of Inroads to Recovery to provide therapeutic services to individuals through a safe environment at the highest level of quality, skill, competence, value and integrity</p>
        </div>
        <div className="about-us-text">
          <h2>Vision</h2>
          <p>Our vision statement is: Those who seek help will find it, and stigma of mental health and addiction will be overcome.</p>
        </div>
        <div className="about-us-text">
          <h2>Values</h2>
          <p>Other core values which effectively serve the mission include:</p>
          <ul>
            <li>We strive for excellence and then push beyond.</li>
            <li>We work as one.</li>
            <li>We are a leader in the community’s health.</li>
            <li>We are patient centered, patient driven.</li>
            <li>We honor and respect the dignity of all.</li>
          </ul>
        </div>

      </div>
      <Sidebar />
    </div>
        </Container>
    );
  }
}

export default About;
