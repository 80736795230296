import React, { Component } from "react";
import "./Meth-Disorder.css";
import Container from "../../../components/Container";
import Sidebar from "../../../components/Sidebar";
import ServicesCTA from "../servicesCTA";


class MethDisorder extends Component {
  render() {
    return (
        <Container backgroundColor="#ffffff">
            <div className="services meth-disorder">
                <div>
                    <h1>Methamphetamine Use Disorder Treatment</h1>
                    <p>We provide evidence based treatment options for methamphetamine use disorders. The most effective treatments for methamphetamine addiction at this point are behavioral therapies, such as cognitive-behavioral and contingency-management interventions. For example, the Matrix Model, a 16-week comprehensive behavioral treatment approach that combines behavioral therapy, family education, individual counseling, 12-Step support, drug testing, and encouragement for non-drug-related activities, has been shown to be effective in reducing methamphetamine abuse.</p>
                    <p>Contingency management interventions, which provide tangible incentives in exchange for engaging in treatment and maintaining abstinence, have also been shown to be effective. Motivational Incentives for Enhancing Drug Abuse Recovery (MIEDAR), an incentive-based method for promoting cocaine and methamphetamine abstinence, has demonstrated efficacy in methamphetamine abusers through NIDA’s National Drug Abuse Clinical Trials Network.</p>
                    <p>Although medications have proven effective in treating some substance use disorders, there are currently no medications that counteract the specific effects of methamphetamine or that prolong abstinence from and reduce the abuse of methamphetamine by an individual addicted to the drug. Severally psychotropic medications have been reported to be helpful in case reports or small studies. We try to make these options available to patients along with the non-medication treatment options to maximize recovery opportunities.</p>
                    <ServicesCTA/>
                </div>
                <Sidebar />
            </div>
        </Container>
    );
  }
}

export default MethDisorder;
