import React, { Component } from "react";
import "./Sidebar.css";
import Carf from "../../img/carf-300x300.png";
import ServicesList from "../../pages/Services/ServicesList";
import {PHONE_NUMBER, FAX_NUMBER, ADDRESS} from "../../constants";

class Sidebar extends Component {
  render() {
    return (
            <div className="sidebar">
                <h2>Contact Info</h2>
                <div>
                  <p><strong>Phone:&nbsp;</strong><br />
                  <a href="tel:4029322248">{PHONE_NUMBER}</a></p>
                  <p><strong>Fax:</strong><br />{FAX_NUMBER}</p>
                  <p><strong>Location:</strong><br /><a href="https://goo.gl/maps/R1vzTABSgdXvAXYh6">{ADDRESS}</a></p>
                  <p><strong>Services:</strong></p>
                  <ServicesList />
                </div>
                <img src={Carf} alt="" width="300" height="300" />
            </div>
    );
    }
}

export default Sidebar;
