import React, { Component } from "react";
import "./Community.css";
import Container from "../../components/Container";
import Sidebar from "../../components/Sidebar";
import Creighton from "../../img/Creighton-University-Logo-Color.svg"
import RVU from "../../img/RVU-Logo.png"
import Union from "../../img/Union-PMS.svg"
class Community extends Component {
  render() {
    return (
        <Container backgroundColor="#ffffff">
          <div className="page-sidebar community-partnership">
            <div>
              <h1>Community Partnerships</h1>
              <div className="logos">
                <img src={Union} alt="Union PMS logo"/>
                <img src={Creighton} alt="Creighton University Logo Color"/>
                <img src={RVU} alt="PVU Logo"/>
              </div>
              <div className="partnership-text">
                <h2>Inroads to Recovery</h2>
                <p>Welcome to Inroads to Recovery, the distinguished psychiatric rotation site selected by <strong>Creighton
                  University</strong> in Omaha, Nebraska, and <strong>Union College</strong> in Lincoln, Nebraska, for their esteemed Physician
                  Assistant students. Our commitment to excellence is exemplified through tailored programs that provide
                  hands-on clinical experiences, expert mentorship, and a supportive learning environment. As a premier
                  educational partner, we take pride in shaping the future of healthcare by equipping students with the
                  skills and knowledge needed to excel in the field of psychiatry.</p>
                <p>Moreover, we are honored to be the top choice for doctor of osteopathic medicine rotation/s and
                  shadowing from <strong>Rocky Vista University</strong> in Boulder, Colorado. Our robust curriculum, led by experienced
                  professionals in the field, ensures that students receive a comprehensive education that goes beyond
                  the classroom. At Inroads to Recovery, we are dedicated to fostering a culture of growth, learning,
                  and innovation, empowering the next generation of healthcare leaders to make a positive impact in the
                  world of medicine. Together we can INCLUDE IMPROVE and INSPIRE</p>
              </div>


            </div>
            <Sidebar/>
          </div>
        </Container>
    );
  }
}

export default Community;
