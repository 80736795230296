import React, { Component } from "react";
import Container from "../../components/Container";
import ServicesCTA from "../Services/servicesCTA";
import Sidebar from "../../components/Sidebar";
import './Faq.css';

class Faq extends Component {
  render() {
    return (
        <Container backgroundColor="#ffffff">
          <div className="services faq">
            <div>
              <h1>Frequently Asked Questions</h1>
              <h2>When was Inroads to Recovery Founded?</h2>
              <p>Inroads to Recovery started 20 years ago in 2004 with only one patient a
                month in one small room has now grown into serving almost three thousand
                patients on a 50,000 SF facility over the course of 20 years.</p>
              <p>We are proud to be Nebraska’s only family, woman, minority owned and
                privately held mental health- substance use facility. We pride ourselves for
                being mental health advocates not just for our patients but also for the
                community at large.</p>
              <h2>Is Inroads to Recovery just a Twelve Steps Program?</h2>
              <p>Twelve Steps Program and its guiding principles are the cornerstone of our
                program, Inroads to Recovery we are very patient centric and the emotional
                and physical wellbeing of every patient is our top priority. Your trust and
                confidence means a great deal to us, our multi-disciplinary team also
                integrates evidence- based therapies and clinical best practices</p>
              <h2>Are Inroads to Recovery’s staff members licensed, and are the programs
                accredited?</h2>
              <p>Yes and yes. We are also licensed in the state of Nebraska and all our
                programs are accredited by CARF international.</p>
              <h2>Does this facility work with inmates who are eligible for parole?</h2>
              <p>Yes, we work with the Nebraska Department of Probation</p>
              <h2>Is it residential or outpatient?</h2>
              <p>We offer Residential level of treatment. We also provide outpatient treatment
                program through our very skilled team of professional, please refer to “our
                teams” page for their detailed bio.</p>
              <h2>What other programs do you offer?</h2>
              <p>We offer Day and Evening Intensive Outpatient Program (IOP). IOP patients
                meet three times a week for three hours each. We sometimes offer weekend
                IOP, for openings please contact our Admissions Coordinator at (402)932-
                2248</p>
              <h2>Would I be able to work while in residential program?</h2>
              <p>In an effort to provide you with the best treatment we do not allow for you to
                work while in the residential treatment facility.</p>
              <h2>Would I get a certificate at the completion of my residential and
                Intensive Outpatient Program (IOP), if I need to show proof to my case
                worker?</h2>
              <p>Yes, we will provide a graduation certificate, and a certificate of participation
                on a case by case basis on approval per Administrations discretion.</p>
              <h2>Does Inroads to Recovery accept Nebraska Medicaid?</h2>
              <p>Yes</p>
              <h2>Does Inroads to Recovery accept Nebraska Medicare??</h2>
              <p>Yes</p>
              <h2>What types of insurances do we accept?</h2>
              <p>We have many different insurances that we accept- please call the front desk
                or our admissions coordinator for further help (402)932-2249.</p>
              <h2>How long is the residential treatment?</h2>
              <p>We offer a 30 day residential treatment program (upon insurance approval).
                Please call our admissions coordinator for any admission’s related questions.</p>
              <h2>What can I bring with me to treatment?</h2>
              <p>The compassionate and caring team at Inroads does its utmost best to provide you with the resources you and your family need to prepare for your inpatient stay at our facility. We understand that knowing what to expect when beginning your recovery journey for a substance use treatment can help make the process seem less daunting. Patients seeking care through our inpatient program should bring:</p>

              <ul>
                <li>A week’s supply of casual clothes, such as jeans, T-shirts, sweats, shorts (if they are not too short).</li>
                <li>30-day supply of personal hygiene items, including hair brushes or combs, deodorant, shampoo, conditioner, toothbrush, toothpaste, shaving equipment, menstrual products, etc.</li>
                <li>Shower shoes</li>
                <li>All medications you are currently taking</li>
              </ul>

              <p>In addition to the general packing list, patients at our facility may bring:</p>

              <ul>
                <li>A personal fan and clock radio</li>
                <li>A curling iron or hair dryer</li>
                <li>Patients age 21 and older can bring store-bought sealed pack of cigarettes</li>
                <li>Envelopes, stationery, and stamps</li>
              </ul>

              <p>We provide all bedding, pillows, and towels for every patient at Inroads to Recovery. Washers, dryers, and laundry soap are also available to our patients at no additional cost. Bedroom placement is determined by the staff and subject to change at any time for incoming/outgoing residents. Residents are not allowed to move from their assigned rooms without permission from medical staff prior to moving.</p>

              <ServicesCTA/>
            </div>
            <Sidebar/>
          </div>
        </Container>
    );
  }
}

export default Faq;
