import React, { Component } from "react";
import './Header.css';
import Container from "../Container";
import {PHONE_NUMBER, ADDRESS, FAX_NUMBER} from "../../constants";

class Header extends Component {


    state = {
        copied: false,
    }

    copyToClipboard = () => {
        const text = {FAX_NUMBER};
        navigator.clipboard.writeText(text)
            .then(() => {
                console.log('Text copied to clipboard');
                this.setState({ copied: true });
                setTimeout(() => this.setState({ copied: false }), 2000); // hide the popup after 2 seconds
            })
            .catch(err => {
                console.error('Could not copy text: ', err);
            });
    }

    // I want to show a little popup that says "Copied!" when the user clicks the fax number

  render() {

        const phoneNumberLink =PHONE_NUMBER.replace(/-/g, '');

    return (
        <Container backgroundColor="#f7f7f7">
            <div className="header">
                <div className="headerTitle"><h1>Inroads to Recovery</h1></div>
                <div><i className="fa-solid fa-phone"></i><span><a href={"tel:" + phoneNumberLink}>{PHONE_NUMBER}</a></span> {/* Phone Number */}</div>
                <div className="headerFax"><span>Fax</span><span className="pointer" onClick={this.copyToClipboard}>{FAX_NUMBER}</span> {/* Fax Number with One Click Copy */}</div>
                <div className={`popup ${this.state.copied ? 'visible' : ''}`}>Copied!</div>
                <div><i className="fa-solid fa-location-dot"></i><span>{ADDRESS}</span> {/* Location */}</div>
            </div>
        </Container>

  );
  }
}

export default Header;
