export const associates = [
    {
        id: 1,
        name: 'S. Pirzada Sattar M.D.',
        title: 'Medical Director Inroads to Recovery and CEO',
        image: './profile/drsattar-240x300.jpg',
        bio: [
            "Diplomate, America Board of Psychiatry and Neurology in General Psychiatry",
            "Diplomate, America Board of Psychiatry and Neurology in Forensic Psychiatry",
            "Diplomate, America Board of Psychiatry and Neurology in Addiction Psychiatry"
        ],
        biolink: '/Our-Team/Dr-Pirzada-Sattar-MD'
    },
    {
        id: 2,
        name: 'Fatima Basith, MA, MPA',
        title: 'Executive Vice President and COO',
        image: './profile/BasithFatima-smile-240x300.jpg',
        bio: [
            "Fatima Basith is the Executive Vice President of Inroads to Recovery and a founding member of the Inroads Foundation. She moved to Omaha, NE about 20 years ago, from Boston, MA after completing her Master’s in Public Administration from Clark University, Massachusetts. She also has a Master’s degree in Political Science from Pakistan, graduating top of her class and earning a national gold medal.",
            "As the Executive Vice President of Inroads to Recovery, and her background in public administration, she has been the driving force behind our patient centric model and our holistic approach towards excellence in patient experience. She believes in ongoing efforts in bringing down the stigma associated with Mental Health and Substance Use Disorders issues.",
            "Fatima has extensive experience working with many local Nonprofits’ Boards in the Omaha Metro Community. Fatima’s hope and goal is to create an inclusive and stigma free community for those that are battling with Mental Health and Substance Use Issues. Providing a safe space where mental health is NEVER an obstacle for any one is her guiding principal.",
        ],
        biolink: ''
    },
    {
        id: 3,
        name: 'Christopher Beasley, PA',
        title: '',
        image: './profile/BeasleyChris-9936-240x300.jpg',
        bio: [
            "Chris earned a Bachelor’s degree in Psychology from the University of Nebraska-Lincoln in 2017. He then graduated from Union College in 2020 with a Master’s Degree in Physician Assistant Studies.",
            "Chris gained experience as a Physician Assistant in acute care and occupational health prior to joining the Inroads to Recovery, INC.  He has a passion for mental health, and he wants to create positive changes and make a lasting impact on the great community of Omaha, NE.",
        ],
        biolink: ''
    },
    // {
    //     id: 4,
    //     name: 'Jacob W. Bailey, PA',
    //     title: '',
    //     image: './profile/JacobBaileyPA.png',
    //     bio: [
    //         "Jake received a Bachelor’s degree in Biology from the University of Nebraska-Omaha in 2016 and then a Master of Medical Science degree from Yale School of Medicine’s Physician Assistant program in 2022. Prior to joining Inroads to Recovery, he worked as a Physician Assistant in a general ENT setting. He is certified by the National Commission on Certification of Physician Assistants and is a member of the American Academy of Physician Assistants.",
    //         "Jake feels fortunate to work in psychiatry and addiction medicine in his hometown. He has a strong commitment to the wellbeing of each patient and enjoys forming meaningful connections with them. He aims to promote positive changes among his patients and to work alongside them towards their goals and paths to wellness.",
    //     ],
    //     biolink: ''
    // },

    {
        id: 5,
        name: 'Veronica Carrasco, PA',
        title: '',
        image: './profile/VC-200x300.jpg',
        bio: [
            "Veronica is originally from Missouri. She earned her Bachelor’s degree in Health Science from Union College in 2021. She continued her education with Union College and received a Master’s Degree in Physician Assistant Studies in 2023. She is a member of the American Academy of Physician Assistants and Nebraska Academy of Physician Assistants.",
            "Veronica is eager to serve the community of Omaha and has a passion for mental health and wellness. She is dedicated to providing patient-centered care. Her approach is to establish a sense of trust and build relationships with patients so that they may feel comfortable sharing their goals and struggles.",
            "Her favorite weekend pastime is solving jigsaw puzzles and cooking for her friends and family."
        ],
        biolink: ''
    },
    {
        id: 4,
        name: 'Rachel Tincher, PA',
        title: '',
        image: './profile/racheltincher-240x300.png',
        bio: [
            "Rachel Tincher is a dedicated Physician Assistant with a strong background in both surgery and nicotine cessation. She earned her undergraduate degree in Exercise Physiology from West Virginia University and furthered her education at Anderson Broaddus University in West Virginia.",
            "Rachel is passionate about helping patients achieve their mental health and personal goals. She finds great fulfillment in seeing her patients make progress and is committed to providing compassionate and effective care. Rachel believes in a holistic approach to healthcare, ensuring that each patient receives personalized treatment tailored to their unique needs. Her empathetic nature and dedication to continuous learning make her an invaluable asset to the Inroads to Recovery team.",
        ],
        biolink: ''
    },
    {
        id: 6,
        name: 'Alexander Gray, MA, NCC, LIMHP',
        title: 'Hidden Dimensions Counseling, LLC at Inroads To Recovery',
        image: './profile/Alex-Grey-240x300.jpg',
        bio: [
            "With 25 years of experience, Alex is a behavioral health services professional, certified as a national counselor, with seasoned experience in clinical supervision; executive administration and development; instruction; facilitation of treatment for psychiatric and substance use disorders among diverse patient populations; program development; compliance with federal / state regulations, and CARF (Commission on Accreditation of Rehabilitation Facilities) accreditation standards.",
            "For 12 years, he served as Clinical Director and psychotherapist for the Eastern Nebraska Community Action Partnership, an antipoverty program assisting low-income families, and served as an Accreditation Surveyor for CARF.",
            "He teaches as an adjunct instructor for the Counselor Education program at the University of Nebraska, Omaha and has sat on numerous boards, task forces, and committees focused on diverse initiatives for community improvement.",
            "Currently, he owns Hidden Dimensions Counseling and is in partnership with Inroads to Recovery in providing outpatient psychotherapy for patients struggling with mental health and substance use issues."
        ],
        biolink: ''
    },
    {
        id: 7,
        name: 'Mark Kinnaman, LADC, IADC',
        title: 'Lead Counselor Residential Program',
        image: './profile/Mark-Kinnaman-240x300.jpg',
        bio: [
            "Mark Currently is the lead Substance Use Disorder Counselor at Inroads to Recovery’s residential co-occurring capable treatment facility, since 2015.",
            "Mark received his Associate Degree in Human Services, Addictive Studies completed May 2007 from Iowa Western Community College and graduated With Honors.",
            "Mark then went on to Buena Vista University to pursue his Bachelor’s Degree in Psychology and Human Services Distributive, July, 2009 and graduated Magna Cum Laude.",
            "Prior to joining Inroads to Recovery Mark had over eight years of experience in a hospital acute care psychiatric department working as a Substance Use Disorders Counselor."
        ],
        biolink: ''
    },
    // {
    //     id: 7,
    //     name: 'Sheila Griffen, LADC',
    //     title: 'Intensive Outpatient Program (IOP) Therapist',
    //     image: './profile/no_image.png',
    //     bio: [
    //         "As a licensed alcohol and drug counselor Sheila has been working within the addiction field since 2012. Sheila received her Associate Degree in Human Services/Psychology in May 1992 from Iowa Western Community College and graduated With Honors.",
    //         "Prior to joining Inroads to Recovery Sheila has had 6 years of experience with multiple levels of care and providing comprehensive support and guidance to individuals and families struggling with substance abuse. With a strong background in evidence-based interventions and a compassionate approach, Sheila is committed to helping clients achieve sustainable recovery and improved overall well-being. Her expertise lies in creating personalized treatment plans, offering therapeutic interventions, and fostering a supportive environment for those on the path to recovery."
    //     ],
    //     biolink: ''
    // },
    // {
    //     id: 8,
    //     name: 'Graciela Mathijssen, PLADC',
    //     title: 'Intensive Outpatient Program (IOP) Therapist',
    //     image: './profile/Graciela.png',
    //     bio: [
    //         "Graciela is a provisionally licensed alcohol and drug counselor in the state of Nebraska, she joined Inroads To Recovery in 2022. Grace oversees the Intensive outpatient (IOP) therapy at the Inroads to Recovery program, and is a member of the Inroads to Recovery residential treatment program and provides individual and group therapy to patients trying to recover from the depths of addiction.",
    //         "Grace is passionate about teaching clients concepts of self-compassion and mindful self-awareness. She bases her therapeutic relationship on the concept of accepting and supporting clients as they are. She recognizes that what a person needs from session to session may vary. She is ready to provide clients with therapeutic interventions ranging from a judgment-free space to talk, to concrete coping skills, and everything in between- thus offering everyone with holistic approach.",
    //         "She has experience and treats a variety of presenting problems including alcohol and drug use disorders. she aims to remain current on the treatment concepts and evidence-based practices used in the treatment of alcohol and drug use disorders."
    //     ],
    //     biolink: ''
    // },
    {
        id: 8,
        name: 'LaDell Maple, PLADC',
        title: 'Intensive Outpatient Program (IOP) Counselor',
        image: './profile/no_image.png',
        bio: [
            "LaDell is a provisionally licensed alcohol and drug counselor in the state of Nebraska, she joined Inroads To Recovery in 2024. LaDell oversees the Intensive outpatient (IOP) therapy at the Inroads to Recovery program, and is a member of the Inroads to Recovery residential treatment program and provides individual and group therapy to patients trying to recover from the depths of addiction.",
            "LaDell is passionate about teaching clients concepts of self-compassion and mindful self-awareness. She bases her therapeutic relationship on the concept of accepting and supporting clients as they are. She recognizes that what a person needs from session to session may vary. She is always ready to provide patients with therapeutic interventions ranging from a judgment-free space to talk, to concrete coping skills, and everything in between- thus offering everyone with holistic approach. She treats a variety of problems including alcohol and drug use disorders. she aims to remain current on the treatment concepts and evidence-based practices used in the treatment of alcohol and drug use disorders."
        ],
        biolink: ''
    },
    {
        id: 8,
        name: 'Pedro Chavez, PLADC',
        title: 'Intensive Outpatient Program (IOP) Counselor',
        image: './profile/pedrochavez-240x300.png',
        bio: [
            "Pedro Chavez is a dedicated professional with a passion for making a positive impact in people’s lives. He holds a PLADC from Bellevue University, equipping him with the knowledge and skills to excel in his field.",
            "With a background in the corrections system, Pedro brings a unique perspective and a wealth of experience to his role at Inroads to Recovery. His work in this challenging environment has honed his ability to connect with individuals from diverse backgrounds and support them on their journey to recovery.",
            "Outside of his professional life, Pedro enjoys traveling and exploring new cultures. His love for travel enriches his understanding of different perspectives, which he integrates into his compassionate approach to helping others.",
            "Pedro is committed to making a difference and is driven by the belief that everyone deserves a chance to lead a fulfilling life. His dedication and empathy make him a valuable member of the Inroads to Recovery team."
        ],
        biolink: ''
    },
    {
        id: 9,
        name: 'Bridgette Thompson-Brown, PLADC',
        title: 'Counselor, Residential Program',
        image: './profile/Bridgette-Thompson-Brown-240x300.jpg',
        bio: [
            "With over 3 years of clinical experience in social services as a practicing counselor in recovery from addiction. Bridgette holds an Associates of Applied Science Degree in Human services and Chemical Dependency Counseling.",
            "Bridgette is a counselor who specializes in human service, trauma-informed and multicultural sensitive care. She is a professional provisionally licensed alcohol and drug counselor at Inroads to Recovery who specializes in coordinating with probation, voucher, and chemical dependency evaluations/referrals.",
            "Bridgette provides individual therapy for people(s) of all ages and backgrounds, she uses a wide range of CBT and evidenced-based treatment modalities to best meet your needs and help achieve your goal. Bridgette has worked with adults in recovery from substances and/or struggling with severe and persistent mental illnesses.",
            "She was inspired by the belief that people can change, her passion is to help individuals and families who are struggling to overcome obstacles and move forward with a new sense of hope and understanding. Bridgette want to live her life to serve others and to make a positive impact and change in the Omaha, Nebraska community."
        ],
        biolink: ''
    },
    {
        id: 10,
        name: 'Trinette Corbin, PLADC',
        title: 'Counselor, Residential Program',
        image: './profile/trinette.png',
        bio: [
            "Trinette has over 2 years of clinical experience and enjoys working with individuals suffering from addiction, co-dependency, low self-esteem, anxiety, grief /loss, depression, and faith based issues. In addition, Trinette works to explore improving relationships through teaching individuals how to establish boundaries, and empower independence.",
            "Trinette utilizes an interactive or hands on approach in which clients begin to explore healthy coping skills to manage stressors, triggers, and cravings.",
            "Trinette Corbin has an Associate in Applied Science in Human Services and Chemical Dependency and has experience working in a group, individual, or family setting. Trinette has a passion to help individuals and families who are struggling to overcome obstacles and move forward with a new sense of hope and understanding."
        ],
        biolink: ''
    },
    // {
    //     id: 10,
    //     name: 'Jasmine Snoddy, MS, PLADC',
    //     title: 'Therapist, Residential Program',
    //     image: './profile/js.png',
    //     bio: [
    //         "As a licensed alcohol and drug counselor Jasmine has been working within the addiction field to help bring the stigma down. Jasmine graduated with a MS degree in Psychology. She is he is passionate about increasing Resources for families, friends, and communities.",
    //         "Prior to joining Inroads to Recovery Jasmine worked at Community Alliance. She has had the experience with multiple levels of care and providing comprehensive support and guidance to individuals and families struggling with substance abuse. With a background in evidence-based interventions and a compassionate approach, Jasmine is committed to helping clients achieve sustainable recovery and improved overall well-being. Her expertise lies in creating personalized treatment plans, offering therapeutic interventions, and fostering a supportive environment for those on the path to recovery."
    //     ],
    //     biolink: ''
    // },
    {
        id: 11,
        name: 'David Ogden, PLADC',
        title: 'Counselor, Residential Program',
        image: './profile/DO.jpg',
        bio: [
            "David Ogden is a dedicated mental health professional with a passion for aiding individuals in their addiction recovery journey. Originally from Papillion, Nebraska, David graduated from Papillion-Lavista High School before obtaining his Associate's Degree in Criminal Justice from Kaplan University. He pursued his education at Mount Marty College in Yankton, South Dakota on a baseball scholarship, delving further into Criminal Justice. David further honed his expertise, graduating from North East Community College in Norfolk, NE, earning his PLADC. His life's mission revolves around supporting those battling the disease of addictions, providing them with tools for sobriety. David thrives on connecting with people from diverse backgrounds and believes he bring valuable insight to those he assists. He is enthusiastic about his role at Inroads to Recovery and looks forward to making a positive impact on the journey to healing and recovery."
        ],
        biolink: ''
    },
    {
        id: 12,
        name: 'Trenda Titsworth, PLADC',
        title: 'Counselor, Residential Program',
        image: './profile/TT.jpg',
        bio: [
            "Trenda is a graduate of Metropolitan Community College with a degree in Chemical Dependency Counseling. Trenda is passionate about helping and serving others.. As a licensed alcohol and drug counselor Trenda has been working within the addiction field to help bring the stigma down. Trenda is passionate about increasing Resources for families, friends, and communities. She has had the experience with multiple levels of care and providing comprehensive support and guidance to individuals and families struggling with substance abuse. With a background in evidence-based interventions and a compassionate approach, Trenda is committed to helping clients achieve sustainable recovery and improved overall well-being. Her expertise lies in creating personalized treatment plans, offering therapeutic interventions, and fostering a supportive environment for those on the path to recovery. Trenda is looking forward to contributing her knowledge and skills to the team at Inroads to Recovery and support others."
        ],
        biolink: ''
    },
    // {
    //     id: 11,
    //     name: 'Carolyn Jacobs, MS, PLADC',
    //     title: 'Therapist, Residential Program',
    //     image: './profile/caryolnjacobs.png',
    //     bio: [
    //         "Carolyn Jacobs was born in Okmulgee OK. She graduated from Bellevue University with a MS degree in Health and Human Services along with a MS in clinical Counseling. She is he is passionate about increasing Resources for families, friends, and communities. She has worked with adults and adolescents in various treatment settings. She enjoys the challenges and rewards of working with individuals with substance use disorders. She strives to show consistent compassion and empathy, while encouraging and empowering her patients on their journey through recovery. Recognizing that the impact of addiction goes beyond the individual who is suffering.",
    //         "Carolyn enjoys spending time with her grandkids and great grandchildren."
    //     ],
    //     biolink: ''
    // },
    {
        id: 16,
        name: 'Ana Rodriguez',
        title: 'Lead Tech, Residential Program',
        image: './profile/AnaRodriguez.png',
        bio: [
            ""
        ],
        biolink: ''
    },
    {
        id: 13,
        name: 'Maria Martinez',
        title: 'Office Administrator',
        image: './profile/Maria-Martinez-240x300.jpg',
        bio: [
            ""
        ],
        biolink: ''
    },
    {
        id: 14,
        name: 'Paula Herrera',
        title: 'Admissions Coordinator, Residential Program',
        image: './profile/paula-240x300.jpg',
        bio: [
            ""
        ],
        biolink: ''
    },
    {
        id: 15,
        name: 'Rosa Vera',
        title: 'Billing Specialist',
        image: './profile/rosavera.png',
        bio: [
            ""
        ],
        biolink: ''
    },
    // {
    //     id: 17,
    //     name: 'Mari Corral',
    //     title: 'Lead Front Desk, Outpatient Program',
    //     image: './profile/mari-corral-200x300.jpg',
    //     bio: [
    //         "",
    //     ],
    //     biolink: ''
    // },
    {
        id: 18,
        name: 'Tatiana Phipps',
        title: 'Lead Front Desk, Outpatient Program',
        image: './profile/TP.jpg',
        bio: [
            "",
        ],
        biolink: ''
    },
];
