import React, { Component } from "react";
import "./Psycheval.css";
import Container from "../../../components/Container";
import Sidebar from "../../../components/Sidebar";
import Waiting from "../../../img/inroads-waiting.png"
import ServicesCTA from "../servicesCTA";

class Psycheval extends Component {
  render() {
    return (
        <Container backgroundColor="#ffffff">
            <div className="services psycheval">
                <div>
                    <h1>Psychiatric Evaluations and Medication Management</h1>
                            <img className="psycheImg" src={Waiting} alt="Waiting"/>
                            <p>We provide evaluations and assessments for all mental health and behavioral health issues
                                as
                                well as help in management of the psychiatric symptoms with medications and
                                psychotherapy.</p>
                            <p>Our approach is to look at the individual as a whole and assess all biological,
                                psychological
                                as well as social risk factors that may be impacting and / or causing the symptoms of
                                mental
                                illness.</p>
                            <p>This approach allows us to make treatment recommendations based on the bio-psycho-social
                                factors, and recommend medications, only when needed. We also are cognizant of the
                                addictive
                                nature of medications and will restrict and/or minimize the use of these types of
                                medications.</p>
                            <p>Emotional health and well-being is directly related to our overall physical health. Just
                                as
                                it’s important to have our physical body checked by the primary care providers, it’s
                                just as
                                important to have our mental health evaluated and treated. Life stressors or trauma can
                                leave us depressed, anxious, overwhelmed, hopeless, agitated and possibly suicidal.
                                Untreated, these feelings can lead to threats to life as well as major psychological and
                                physical illnesses or to behaviors that can become addictive as well as destructive.</p>
                            <p>Our mental health providers will perform an initial checkup of mental health and its
                                symptoms
                                similar to what a primary care provider might do by asking questions about physical
                                health.
                                This checkup, called a mental health assessment or a psychiatric assessment, includes an
                                overview of the individual’s symptoms and a determination of whether or not medication
                                is a
                                good option for the treatment of the individual’s mental health. Where our primary care
                                colleagues address more physical aspects of treatment, we utilize discussion to get to
                                the
                                root of the mental health care needs.</p>

                            <p><strong>If you are experiencing mental health issues, and would like to discuss whether
                                medication is a
                                good option for you, please call our clinic at (402) 932-2248.</strong></p>
                    <p>Based on this discussion and assessment, a diagnosis is made and a treatment plan is
                        developed with the patient at the center. If it’s in the patient’s best interests,
                        medications will be discussed and education will be provided on the actions, uses, and
                        side
                        effects of the suggested medication options. When the patient agrees on the use of a
                        medication or multiple medications as the need might be, these will be prescribed for a
                        trial period to observe the effectiveness. This is the “medication management,” aspect
                        of
                        psychiatric care. We monitor the effectiveness of medication over a course of time to
                        determine if the treatment meets the patient’s goals. Due to how psychiatric medications
                        react differently within a patient’s body, the effectiveness of the medications may vary
                        from person to person.</p>
                    <p>Every individual is unique. Medications are often utilized at the same time as other
                        forms of
                        treatment such as counseling, life management skills, and/or behavioral therapies.
                        Through
                        careful monitoring and on-going discussion we find the right medication and treatment
                        plans
                        to improve the patient’s mental health.</p>

                    <p>The use of psychiatric medications introduces potential risks and side effects, as with
                        any
                        medication. These are all discussed and weighed against the benefits of the medications,
                        with each patient. Not all patients and not all psychiatric problems need medicines, but
                        there are certainly instances where prescription medicines are the best treatment
                        available
                        to relieve a patient’s suffering.</p>
                    <p>Medicines are commonly an important and effective part of the treatment of many mental
                        illnesses to include depression, schizophrenia, schizoaffective disorder, bipolar
                        disorder,
                        post traumatic stress disorder, anxiety, panic attacks and disorder, sleep problems, and
                        several other disorders.</p>
                    <ServicesCTA/>
                </div>
                <Sidebar/>
            </div>
        </Container>
    );
  }
}

export default Psycheval;
