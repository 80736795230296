import IOP from "../Services/IOP";
import Psycheval from "../Services/PsychEval";
import OutpatientPsych from "../Services/Outpatient-Psych";
import MethDisorder from "../Services/Meth-Disorder";
import ResidentialProgram from "../Services/Residential-Program";
import SubstanceAbuse from "../Services/Substance-Abuse";
import OpiateUse from "../Services/Opiate-Use";
import AlcoholUseDisorder from "../Services/Alcohol-Use-Disorder";
import Tianeptine from "../Services/Tianeptine";
import Inhalant from "../Services/Inhalant";
import Phencyclidine from "./Phencyclidine";
import Vaping from "./Vaping";
import Kratom from "./Kratom";


const servicesData = [
    {
        name: "Intensive Outpatient Treatment (IOP)",
        link: "/services/Intenstive-Outpatient-Treatment-IOP",
        component: IOP
    },
    {
        name: "Psychiatric Evaluations and Medication Management",
        link: "/services/Psychiatric-Evaluations-Medication-Management",
        component: Psycheval
    },
    {
        name: "Outpatient Psychotherapy",
        link: "/services/Outpatient-Psychotherapy",
        component: OutpatientPsych
    },
    {
        name: "Substance Use Evaluations",
        link: "/services/Substance-Use-Evaluations",
        component: SubstanceAbuse
    },
    {
        name: "Residential Treatment",
        link: "/services/Residential-Program",
        component: ResidentialProgram
    },
    {
        name: "Opiate Use Treatment",
        link: "/services/Opiate-Use-Treatment",
        component: OpiateUse
    },
    {
        name: "Alcohol Use Disorder Treatment",
        link: "/services/Alcohol-Use-Disorder-Treatment",
        component: AlcoholUseDisorder
    },
    {
        name: "Methamphetamine Use Disorders Treatment",
        link: "/services/Methamphetamine-Use-Disorders-Treatment",
        component: MethDisorder
    },
    {
        name: "Tianeptine Withdrawal Symptoms & Treatment",
        link: "/services/Tianeptine-Withdrawal-Symptoms-Treatment",
        component: Tianeptine
    },
    {
        name: "Inhalant Addiction and Treatment",
        link: "/services/Inhalant-Addiction-Treatment",
        component: Inhalant
    },
    {
        name: "Phencyclidine Addiction",
        link: "/services/Phencyclidine-Addiction",
        component: Phencyclidine
    },
    {
        name: "Vaping: The Health Risks of Nicotine Addiction and How to Quit",
        link: "/services/Vaping-The-Health-Risks-of-Nicotine-Addiction-and-How-to-Quit",
        component: Vaping
    },
    {
        name: "Kratom Addiction",
        link: "/services/Kratom-Addiction",
        component: Kratom
    },

];

export default servicesData;
